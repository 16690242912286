import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../Layout";
import CheckIn from "./CheckIn";
import Home from "./Home";
import Invitation from "./Invitation";
import List from "./List";
import NotFound from "./NotFound";

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Invitation />} path="/invitation/:id" />
          <Route element={<CheckIn />} path="/event/checkin/:id" />
          <Route element={<List />} path="/event/" />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
