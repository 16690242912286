import {
  Avatar,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/gif/STD_Cannelle.gif";

const Registration = () => {
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    fetch(process.env.REACT_APP_API_URL + "/invitation/", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then(({ _id }) => {
        navigate(`/invitation/${_id}`);
      });
  };

  return (
    <Box
      sx={{
        mt: 4,
        textAlign: "center",
      }}
    >
      <Avatar
        sx={{ my: 3, mx: "auto", width: 200, height: 200 }}
        src={Logo}
      ></Avatar>
      <Typography component="h1" variant="h5">
        Can’s Birthday
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Nom"
              name="lastName"
              autoComplete="family-name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="firstName"
              label="Prénom"
              name="firstName"
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <InputLabel id="confirmationLabel">Confirmation</InputLabel>
              <Select
                labelId="confirmationLabel"
                id="confirmation"
                label="Confirmation"
                name="status"
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value)}
              >
                <MenuItem value={"PRESENT"}>Je serai présent</MenuItem>
                <MenuItem value={"ABSENT"}>Je ne serai pas présent</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
        >
          Confirmer
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Registration;
