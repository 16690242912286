import { Box, Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container
        fixed
        maxWidth={location.pathname.match(/event/) ? null : "xs"}
        component="main"
      >
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
