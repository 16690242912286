import { Box } from "@mui/material";
import Registration from "../../components/Registration";

const Home = () => {
  return (
    <Box>
      <Registration />
    </Box>
  );
};

export default Home;
