import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { useCallback, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Logo from "../../assets/images/svg/LOGO_CanelleBday.svg";

const Invitation = () => {
  const { id } = useParams();
  const link = window.location.origin + "/event/checkin/" + id;

  const [data, setData] = useState(null);
  const [sendingConfirmation, setSendingConfirmation] = useState(null);
  const [successConfirmation, setSuccessConfirmation] = useState(false);

  const sendEmail = useCallback(() => {
    if (data?.status === "PRESENT")
      html2canvas(document.querySelector("#react-qrcode-logo")).then(
        (canvas) => {
          setSendingConfirmation(true);
          fetch(
            process.env.REACT_APP_API_URL +
              "/invitation/" +
              id +
              "/sendConfirmation",
            {
              method: "POST",
              body: JSON.stringify({
                image: canvas.toDataURL(),
                subject: "QR Code Can’s Bday",
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            setSendingConfirmation(false);
            if (res.ok || res.status === 403) {
              setSuccessConfirmation(true);
            } else {
              setSuccessConfirmation(false);
            }
          });
        }
      );
  }, [id, data?.status]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/invitation/" + id)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .then(() => {
        sendEmail();
      });
  }, [id, sendEmail]);

  const handleDownload = () => {
    html2canvas(document.querySelector("#react-qrcode-logo")).then((canvas) => {
      const link = document.createElement("a");
      link.download = "react-qrcode-logo.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  if (!data)
    return (
      <Box
        display={"flex"}
        flexDirection={"rox"}
        alignItems={"center"}
        gap={"10px"}
        justifyContent={"center"}
        mt={"40vh"}
      >
        <CircularProgress />
        <Typography>Loading...</Typography>
      </Box>
    );
  if (data?.status === "ABSENT")
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        mt={3}
      >
        <Typography variant="h4">Too bad !</Typography>
        <Typography>
          Dommage que tu ne sois pas des nôtres, ça sera pour l'année prochaine.
        </Typography>
      </Box>
    );

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={3}>
      <Typography variant="h4">You’re in !</Typography>
      <Typography>Hâte vous retrouver.</Typography>
      <Typography>Save your QR code !</Typography>
      <Box sx={{ filter: "invert(0.93)" }}>
        <QRCode
          bgColor="transparent"
          logoImage={Logo}
          size={300}
          quietZone={20}
          ecLevel="Q"
          qrStyle="squares"
          value={link}
          eyeRadius={[
            [50, 50, 0, 50],
            [50, 50, 50, 0],
            [50, 0, 50, 50],
          ]}
          removeQrCodeBehindLogo={true}
        />
      </Box>
      <Box mt={2}>
        <Button variant="contained" onClick={handleDownload}>
          Télécharger
        </Button>
      </Box>
      <Box mt={2}>
        <LoadingButton
          loading={sendingConfirmation}
          variant="contained"
          color={
            sendingConfirmation !== null
              ? successConfirmation
                ? "success"
                : "error"
              : "primary"
          }
          loadingPosition="start"
          startIcon={
            sendingConfirmation !== null ? (
              successConfirmation ? (
                <CheckIcon />
              ) : (
                <ClearIcon />
              )
            ) : (
              <SendIcon />
            )
          }
        >
          {sendingConfirmation !== null && sendingConfirmation === false
            ? successConfirmation
              ? "Confirmation envoyée"
              : "Erreur lors de l'envoi"
            : "Confirmation"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Invitation;
