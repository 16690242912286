import { Box, Chip, ToggleButton, Typography } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const List = () => {
  const [data, setData] = useState(null);

  const getData = () => {
    fetch(process.env.REACT_APP_API_URL + "/invitation/")
      .then((res) => res.json())
      .then(({ invitations }) => {
        setData(
          invitations.map((invitation) => ({
            ...invitation,
            id: invitation._id,
          }))
        );
      });
  };

  const deleteGuest = (id) => {
    fetch(process.env.REACT_APP_API_URL + "/invitation/" + id, {
      method: "delete",
    })
      .then((res) => res.json())
      .then((data) => {
        getData();
      });
  };

  const checkInGuest = (id, checkedIn) => {
    fetch(process.env.REACT_APP_API_URL + "/invitation/" + id, {
      method: "put",
      body: JSON.stringify({ checkedIn: !checkedIn }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getData();
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Typography>List invité - Anniversaire Cannelle</Typography>
      <Box sx={{ height: "700px", width: "100%" }}>
        {data && (
          <DataGrid
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
            columns={[
              { field: "id", headerName: "ID", hide: true },
              { field: "firstName", headerName: "Prénom", flex: 1 },
              { field: "lastName", headerName: "Nom", flex: 1 },
              { field: "email", headerName: "Email", flex: 1 },
              {
                field: "status",
                headerName: "Réponse",
                flex: 1,
                maxWidth: 130,
                minWidth: 130,
                renderCell: (params) => {
                  if (params.value === "PRESENT") {
                    return (
                      <Chip
                        icon={<CheckIcon />}
                        label="Présent"
                        color="success"
                        variant="outlined"
                      />
                    );
                  }
                  if (params.value === "ABSENT") {
                    return (
                      <Chip
                        icon={<ClearIcon />}
                        label="Absent"
                        color="error"
                        variant="outlined"
                      />
                    );
                  }
                  return (
                    <Chip
                      label="Sans réponse"
                      color="primary"
                      variant="outlined"
                    />
                  );
                },
              },
              {
                field: "checkedIn",
                headerName: "CheckIn",
                type: "actions",
                maxWidth: 80,
                minWidth: 80,
                getActions: (params) => [
                  <ToggleButton
                    variant="outlined"
                    size="small"
                    value="check"
                    color={params.row.checkedIn ? "success" : "warning"}
                    selected={params.row.checkedIn}
                    onChange={() => {
                      checkInGuest(params.id, params.row.checkedIn);
                    }}
                  >
                    {params.row.checkedIn ? (
                      <CheckIcon fontSize="small" />
                    ) : (
                      <ClearIcon fontSize="small" />
                    )}
                  </ToggleButton>,
                ],
              },
              {
                field: "actions",
                headerName: "Gerer",
                type: "actions",
                maxWidth: 60,
                minWidth: 60,
                getActions: (params) => [
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    onClick={() => deleteGuest(params.id)}
                    label="Delete"
                  />,
                ],
              },
            ]}
            rows={data}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        )}
      </Box>
    </>
  );
};

export default List;
