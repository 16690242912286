import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      marginTop={"50px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">NotFound</Typography>
      <Typography>La page est introuvable</Typography>
      <Box>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
        <Button onClick={() => navigate("/")}>Go to homepage</Button>
      </Box>
    </Box>
  );
};

export default NotFound;
