import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const CheckIn = () => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/invitation/" + id)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        if (data.checkedIn === false) {
          fetch(process.env.REACT_APP_API_URL + "/invitation/" + id, {
            method: "put",
            body: JSON.stringify({ checkedIn: true }),
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
      });
  }, [id]);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography variant="h5">CheckIn Portal {id}</Typography>
      {data &&
        (data.error ? (
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <HighlightOffIcon style={{ fontSize: 200 }} color="error" />
            <Typography variant="h6">Invité introuvable</Typography>
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            {!data.checkedIn && data.status === "PRESENT" ? (
              <>
                <CheckCircleOutlineIcon
                  style={{ fontSize: 200 }}
                  color="success"
                />
              </>
            ) : (
              <>
                <ErrorOutlineIcon style={{ fontSize: 200 }} color="warning" />
                <Typography variant="h6">
                  {data.status === "PRESENT"
                    ? "Invité déjà enregistré"
                    : data.status === "ABSENT"
                    ? "Invitation refusée"
                    : "Invitation non confirmée"}
                </Typography>
              </>
            )}
            <Typography>Prénom: {data.firstName}</Typography>
            <Typography>Nom: {data.lastName}</Typography>
            <Typography>Email: {data.email}</Typography>
          </Box>
        ))}
    </Box>
  );
};

export default CheckIn;
