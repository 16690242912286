import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { createTheme } from "@mui/material";

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

export const theme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: ['"Helvetica Regular"', "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            backgroundColor: "transparent !important",
            transition: "background-color 600000s 0s, color 600000s 0s",
          },
        },
      },
    },
  },
});
